<template>
  <div>
    <v-card-title
      class="ma-0 pa-0 text-body-1"
    >
      {{ attachToIdentifiers.participant.formData.subject }}
    </v-card-title>
    <v-container
      fluid
      class="uploader pa-3"
      :class="{ dragging: isDragging }"
      @dragenter="OnDragEnter"
      @dragleave="OnDragLeave"
      @dragover.prevent
      @drop="onDrop"
    >
      <app-api-call
        class="mb-3"
        :call-result="callResult"
      />

      <v-file-input
        v-model="selectedFile"
        color="success"
        class="subtitle-2"
        :placeholder="$i18n.t('$l.resolve.selectYourFile')"
        prepend-icon="mdi-paperclip"
        outlined
        @change="onInputChange"
      >
        <template v-slot:selection="{ text }">
          <v-chip
            color="success"
            dark
            label
            small
          >
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>

      <v-row>
        <v-card-text
          class="subtitle-2"
        >
          <v-icon
            large
            class="mr-5"
          >
            mdi-cloud-upload-outline
          </v-icon>
          {{ '$l.program.orMoveFileHere' | translate }}
        </v-card-text>
        <v-card-text
          class="info2--text ma-0 pa-0"
        >
          {{ $i18n.t('$l.resolve.attachFileInfo') }}
        </v-card-text>
      </v-row>
    </v-container>

    <v-container
      fluid
    >
      <v-row>
        <v-spacer />
        <v-btn
          :disabled="(selectedFile && callResult.error === null) ? false : true"
          class="primary"
          text
          :loading="fileIsBeingUploaded"
          @click="upload"
        >
          {{ $i18n.t('$l.app.confirm') }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AttachmentFileUploader',
  props: {
    attachToIdentifiers: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      selectedFile: null,
      isDragging: false,
      dragCount: 0,
      fileIsBeingUploaded: false
    }
  },
  methods: {
    onInputChange (e) {
      if (e !== null) {
        this.checkAndLoadFile(e)
      }
    },
    OnDragEnter (e) {
      e.preventDefault()
      this.dragCount++
      this.isDragging = true
      return false
    },
    OnDragLeave (e) {
      e.preventDefault()
      this.dragCount--
      if (this.dragCount <= 0) { this.isDragging = false }
    },
    onDrop (e) {
      e.preventDefault()
      e.stopPropagation()
      this.isDragging = false
      const files = e.dataTransfer.files
      if (files.length === 1) {
        this.selectedFile = files[0]
        this.checkAndLoadFile(this.selectedFile)
      } else {
        this.callResult.error = this.$i18n.t('$l.program.notValidTypeOfFile')
      }
    },
    checkAndLoadFile (file) {
      this.callResult.error = null
      if (file) {
        if (file.size) {
          if (file.size > 1048576 * 2) {
            this.callResult.error = this.$i18n.t('$l.resolve.fileTooBigOrSizeUnknown')
          }
        } else {
          this.callResult.error = this.$i18n.t('$l.resolve.fileTooBigOrSizeUnknown')
        }
        if (file.name.length > 128) {
          this.callResult.error = this.$i18n.t('$l.resolve.fileNameConstraintExceeded')
        }
      }
    },
    upload () {
      if (!this.selectedFile) return
      this.callResult.error = null
      this.fileIsBeingUploaded = true
      let formData = new FormData()
      formData.append('byteData', this.selectedFile)
      formData.append('fileName', this.selectedFile.name)
      formData.append('participantId', this.attachToIdentifiers.participant.id)
      formData.append('caseId', this.attachToIdentifiers.caseId)
      this.$xapi.post('endpointsFile/casefile/upload', formData)
        .then(() => {
          this.selectedFile = null
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.fileIsBeingUploaded = false
          this.$emit('closeAttachmentDialog')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .uploader {
    width: 100%;
    text-align: center;
    border: 3px dashed $greycc;
    &.dragging {
      background: #fff;
      border: 3px dashed $success;
      .file-input label {
          background: $success;
      }
    }
  }
</style>
