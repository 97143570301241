<template>
  <basic-card
    :title="`${$i18n.t('$l.resolve.resolveCase')} (${$i18n.t(`$l.case.${currentCase.type}`)})`"
    text-class="body-1 font-weight-light ma-0 pa-0 mt-2"
  >
    <basic-note-hint
      v-if="currentCase.status !== 'CLOSED'"
      class="mb-2"
    >
      {{ '$l.resolve.resolveCaseHint' | translate }}
    </basic-note-hint>

    <v-row>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        cols="12"
        class="blu"
      >
        <ResolveCaseSemafor />
      </v-col>

      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        cols="12"
        class="re"
      >
        <v-card
          outlined
          class="pa-3"
        >
          <v-card-title
            class="ma-0 pa-0"
          >
            <span
              style="line-height: 1em"
            >
              {{ $i18n.t('$l.case.name') + ': ' + currentCase.name }}
            </span>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col
        v-if="checkWarnings() !== null"
        cols="12"
      >
        <v-card
          outlined
          class="error--text pa-3"
        >
          {{ checkWarnings() }}
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-expansion-panels
          v-model="openPanels"
          class="ma-0 pa-0"
        >
          <v-expansion-panel
            v-if="currentCase.status !== 'CLOSED'"
            class="label--text text--darken-2"
          >
            <v-expansion-panel-header class="ma-0 pa-0">
              <v-card-title> {{ "$l.case.changeCaseRisk" | translate }}</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-0 px-3">
              <v-radio-group
                v-model="radioGroup"
                class="ma-0 pa-0"
                :mandatory="false"
              >
                <v-radio
                  v-for="n in levelRiskItems"
                  :key="n.value"
                  :label="n.text"
                  :value="n.value"
                />
              </v-radio-group>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel
            class="label--text text--darken-2"
            @click="reloadCareView"
          >
            <v-expansion-panel-header class="ma-0 pa-0">
              <v-card-title> {{ $i18n.t('$l.resolve.careAndAttachments') }}</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-0 pa-0">
              <CareView
                ref="careView"
                :key="careViewReloadingKey"
                @reloadCareView="reloadCareView()"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel
            class="label--text text--darken-2"
            @click="reloadPdfReport"
          >
            <v-expansion-panel-header class="ma-0 pa-0">
              <v-card-title> {{ $i18n.t('$l.resolve.showPdfReport') }}</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-0 pa-0">
              <PdfView
                ref="pdfView"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel
            :disabled="currentCase.status !== 'CLOSED'"
            class="label--text text--darken-2"
          >
            <v-expansion-panel-header class="ma-0 pa-0">
              <v-card-title> {{ $i18n.t('$l.resolve.reportNoo') }}</v-card-title>
              <v-card-title
                v-if="currentCase.status !== 'CLOSED'"
                class="body-1"
              >
                ({{ "$l.resolve.pdfReportAvailableAfterClose" | translate }})
              </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-0 pa-0">
              <basic-note-hint
                class="ma-3 mt-0"
              >
                {{ $i18n.t('$l.archive.emailToFsj') }}
              </basic-note-hint>
              <v-row class="ma-0 pa-2 justify-end mb-3">
                <v-btn
                  text
                  class="primary ma-1"
                  href="/hlasenie.doc"
                  download
                >
                  <v-icon left>
                    mdi-file
                  </v-icon> {{ $i18n.t('$l.resolve.downloadNooTemplate') }}
                </v-btn>
                <v-btn
                  text
                  class="primary ma-1"
                  href="mailto:sjfpnoo@minv.sk"
                  target="_blank"
                  title="sjfpnoo@minv.sk"
                >
                  <v-icon left>
                    mdi-email
                  </v-icon>
                  {{ $i18n.t('$l.archive.emailToFsjSend') }}
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-btn
          v-if="currentCase.status === 'CLOSED'"
          class="float-right primary ma-3 mr-0"
          text
          :loading="downloadPdfProgress"
          style="z-index: 1"
          @click="downloadPdf"
        >
          {{ '$l.resolve.downloadPdfReport' | translate }}
        </v-btn>
      </v-col>

      <v-col
        v-if="!callResult1.finished || callResult1.error || callResult1.info"
        cols="12"
      >
        <app-api-call
          :call-result="callResult1"
        />
      </v-col>

      <v-col
        v-if="currentCase.status !== 'CLOSED'"
        cols="12"
      >
        <v-textarea
          ref="textarea"
          v-model="statement"
          :label="$i18n.t()"
          class="ma-0"
          outlined
          filled
          rows="4"
          background-color="greyf6"
          color="success darken-1"
          :placeholder="'$l.resolve.finalStatement' | translate"
          prepend-inner-icon="mdi-comment-text"
          :rules="[v => v ? (v.length >= 10 ? true : $i18n.t('$l.app.min10charsRequired')) : '']"
          maxlength="512"
          counter
          :error-messages="($v.statement.$dirty && !$v.statement.required) ? $i18n.t('$l.app.required') : ''"
          @input="$v.statement.$touch()"
          @blur="$v.statement.$touch()"
        />
        <v-btn
          class="float-right primary"
          text
          :loading="finishCaseProgress"
          @click="finishCase"
        >
          {{ '$l.resolve.finishCase' | translate }}
        </v-btn>
      </v-col>
    </v-row>

    <PdfView
      v-show="false"
      ref="downloadPdfView"
      @downloadPdfProgressFinished="downloadPdfProgress = false"
    />

    <basic-dialog
      v-model="confirmFinishDialog"
      title="$l.resolve.confirmFinishCaseTitle"
      subtitle="$l.resolve.confirmFinishCase"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="confirmFinishDialog = false"
      @save-dialog="confirmFinishCase"
    />
  </basic-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import ResolveCaseSemafor from './ResolveCaseSemafor.vue'
import CareView from './CareView'
import PdfView from './PdfView.vue'

export default {
  name: 'ResolveCaseWrapper',
  components: {
    ResolveCaseSemafor,
    CareView,
    PdfView
  },
  data () {
    return {
      callResult1: { finished: true, authorized: false, error: null, info: null },
      openPanels: [],
      finishCaseProgress: false,
      statement: '',
      confirmFinishDialog: false,
      downloadPdfProgress: false,
      careViewReloadingKey: 0,
      levelRiskItems: [
        {
          text: this.$i18n.t('$l.case.level0'), value: 0
        },
        {
          text: this.$i18n.t('$l.case.level1'), value: 1
        },
        {
          text: this.$i18n.t('$l.case.level2'), value: 2
        }
      ]
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    radioGroup: {
      get () {
        return this.currentCase.result.userDefinedRiskLevel
      },
      set (val) {
        this.currentCase.result.userDefinedRiskLevel = val
        this.$xapi.put('case/update', this.currentCase)
          .then(r => {
            this.$store.commit('SET_CURRENT_CASE', r.data)
          })
      }
    }
  },
  validations: {
    statement: { required }
  },
  methods: {
    checkWarnings () {
      let sanctionHitConfirmed = false
      this.currentCase.participants.forEach(p => {
        p.investigations.forEach(i => {
          if (i.clientApi === 'EUSL' || i.clientApi === 'CSSDN' || i.clientApi === 'USDN') {
            if (i.result.userDefinedRiskLevel > 0) {
              sanctionHitConfirmed |= true
            }
          }
        })
      })
      if (sanctionHitConfirmed) {
        return this.$i18n.t('$l.investigation.sanctionListHitConfirmed')
      } else {
        return null
      }
    },
    finishCase () {
      this.$v.$touch()
      if (!this.$v.$invalid && this.$refs.textarea.validate()) {
        this.confirmFinishDialog = true
      }
    },
    confirmFinishCase () {
      this.finishCaseProgress = true
      this.callResult1.finished = false
      this.currentCase.result.finalStatement = this.statement
      this.confirmFinishDialog = false
      this.$xapi.post('case/finishCase', this.currentCase)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.callResult1.info = this.$i18n.t('$l.error.movedToArchive')
        })
        .catch(() => {
          this.callResult1.error = this.$i18n.t('$l.errorcode.default')
        })
        .finally(() => {
          this.callResult1.finished = true
          this.finishCaseProgress = false
        })
    },
    reloadPdfReport () {
      if (this.$refs.pdfView) {
        this.$refs.pdfView.loadPdfReport()
      }
    },
    downloadPdf () {
      if (this.$refs.downloadPdfView) {
        this.downloadPdfProgress = true
        this.$refs.downloadPdfView.downloadPdf()
      }
    },
    reloadCareView () {
      if (this.$refs.careView) {
        this.careViewReloadingKey++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {.v-expansion-panel-content__wrap {
    padding: 0px !important;
  }}
</style>
