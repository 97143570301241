<template>
  <div>
    <app-api-call
      :call-result="callResult"
      class="mx-3 py-1"
    />

    <template
      v-for="(p, idx) in currentCase.participants"
    >
      <v-row
        :key="idx"
        dense
      >
        <v-col
          class=""
        >
          <v-card-title
            class="text-body-1 py-0 font-weight-medium"
            :class="{'primary--text' : p.result.highCare}"
          >
            {{ p.formData.subject + ' - ' + (p.result.highCare ? $i18n.t('$l.resolve.participantHighCare') : $i18n.t('$l.resolve.participantBasicCare')) }}
          </v-card-title>

          <v-card-title
            class="text-body-2 py-0"
          >
            {{ $i18n.t('$l.resolve.higherRisks') }}
            <template
              v-for="(invst, idx2) in p.investigations"
            >
              <div
                v-if="invst.result.riskLevel > 0"
                :key="idx2"
                class="ml-4"
              >
                {{ $i18n.t(`$l.caseinv.${invst.clientApi}`) }}
              </div>
            </template>
          </v-card-title>

          <v-card-title
            class="text-body-2 py-0"
          >
            {{ $i18n.t('$l.resolve.attachments') }}
            <template
              v-for="(attachment, idx2) in caseAttachments"
            >
              <div
                v-if="attachment.participantId && attachment.participantId === p.id"
                :key="idx2"
                class="ml-4"
              >
                <v-list-item
                  class="ma-0 pa-0"
                  dense
                  @click="downloadAttachment(attachment)"
                >
                  <v-icon
                    left
                    class="info2--text ma-0 pa-0"
                  >
                    mdi-clippy
                  </v-icon>
                  <span class="info2--text ma-0 pa-0">
                    {{ attachment.fileName }}
                  </span>
                </v-list-item>
              </div>
            </template>
          </v-card-title>

          <v-card-actions>
            <v-row>
              <v-switch
                v-model="p.result.highCare"
                :label="$i18n.t('$l.resolve.participantHighCare')"
                color="primary"
                hide-details
                class="mx-4 mt-1 pt-0"
                @click="updateCase"
              />
              <v-btn
                color="primary darken-1"
                text
                @click="openAttachmentDialog(p)"
              >
                {{ $i18n.t('$l.resolve.uploadAttachment') }}
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-divider class="mt-1 mx-3" />
        </v-col>
      </v-row>
    </template>

    <basic-dialog
      v-model="addAttachmentDialog"
      title="$l.resolve.uploadAttachment"
      @close-dialog="closeAttachmentDialog()"
    >
      <AttachmentFileUploader
        :attach-to-identifiers="attachToIdentifiers"
        class="ma-3"
        @closeAttachmentDialog="closeAttachmentDialog()"
      />
    </basic-dialog>
  </div>
</template>

<script>
import AttachmentFileUploader from './AttachmentFileUploader.vue'

const readFile = (inputFile) => {
  const fileReader = new FileReader()
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new Error('Problem parsing input file.'))
    }
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.readAsDataURL(inputFile)
  })
}

export default {
  name: 'CareView',
  components: {
    AttachmentFileUploader
  },
  data () {
    return {
      callResult: { finished: false, authorized: true, error: null, info: null },
      addAttachmentDialog: false,
      attachToIdentifiers: {},
      currentCase: {},
      caseAttachments: []
    }
  },
  created () {
    this.reloadCase()
  },
  methods: {
    updateCase () {
      this.$xapi.put('case/update', this.currentCase)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
        })
    },
    openAttachmentDialog (participant) {
      this.addAttachmentDialog = true
      this.attachToIdentifiers = { caseId: this.currentCase.caseId, participant: participant }
    },
    closeAttachmentDialog () {
      this.addAttachmentDialog = false
      this.$emit('reloadCareView')
    },
    reloadCase () {
      this.currentCase = {}
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('case/caseId/' + this.$route.params.caseId)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.$xapi.get('endpointsFile/casefile/caseId/' + r.data.caseId + '/list')
            .then(r => {
              this.caseAttachments = r.data
            })
            .catch(e => {
              this.callResult.error = e.response.localizedMessage
            })
            .finally(() => {
              this.currentCase = this.$store.getters.getCurrentCase
              this.callResult.finished = true
            })
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
    },
    downloadAttachment (attachment) {
      this.$xapi.post('endpointsFile/casefile/download', attachment, { responseType: 'blob' })
        .then(r => {
          readFile(r.data)
            .then(fd => {
              const downloadLink = document.createElement('a')
              document.body.appendChild(downloadLink)
              downloadLink.href = fd
              downloadLink.target = '_self'
              downloadLink.download = '_' + attachment.fileName
              downloadLink.click()
            })
        })
    }
  }
}
</script>
