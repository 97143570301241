<template>
  <v-card
    outlined
    class="pa-3"
  >
    <v-card-title
      class="semafor ma-0 pa-0 px-1"
      :class="{'align-start': currentCase.result.userDefinedRiskLevel === 0, 'align-center': currentCase.result.userDefinedRiskLevel === 1, 'align-end': currentCase.result.userDefinedRiskLevel === 2} "
    >
      <span
        style="line-height: 1em"
        :class="computeColorClass(false, currentCase.result, '--text darken-1')"
      >
        {{ "$l.case.overallCaseRisk" | translate }}
        {{ $i18n.t(`$l.case.level${currentCase.result.userDefinedRiskLevel}`) }}
      </span>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'ResolveCaseSemafor',
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  methods: {
    computeColorClass (reccomended, result, append) {
      let color = 'label'
      let level = reccomended ? result.riskLevel : result.userDefinedRiskLevel
      if (level === 0) {
        color = 'success'
      }
      if (level === 1) {
        color = 'warning'
      }
      if (level === 2) {
        color = 'error'
      }
      return color + append
    }
  }
}
</script>

<style lang="scss" scoped>
  .semafor {
    border-left-width: 0.4em;
    border-left-style: solid;
    border-image: linear-gradient(to bottom, $success, $warning, $error) 1 100%;
  }
  .semafor_height {
    height: 5em;
  }
</style>
