<template>
  <div>
    <v-card-text
      v-if="currentCase.status !== 'CLOSED'"
      class="info1--text ma-0 pa-0 pa-3 pt-0"
    >
      {{ $i18n.t('$l.resolve.pdfReportDownloadAfterClose') }}
    </v-card-text>

    <app-api-call
      :call-result="callResult2"
      class="mx-3 py-1"
    />

    <v-card-text class="ma-0 pa-0 greyee">
      <pdf
        v-for="i in numPages"
        ref="pdfReport"
        :key="i"
        :src="pdfDocumentLoadingTask"
        :page="i"
        class="ma-0 pa-3"
      />
    </v-card-text>

    <basic-dialog
      v-model="pdfEncryptionHintDialog"
      title="$l.resolve.downloadPdfReport"
      save-button
      save-button-label="$l.resolve.downloadPdfReport"
      @close-dialog="pdfEncryptionHintDialogClose"
      @save-dialog="doDownloadPdf"
    >
      <basic-service-required
        title="$l.resolve.pdfEncryptedHint"
        dclass="info1--text greyee text-caption mx-3"
        dstyle="border: 1px solid #ccc !important; border-radius: 0.3em;"
      >
        <template v-slot:customButton>
          <v-btn
            text
            class="primary float-right mt-2"
            @click="editPdfReportPassword = !editPdfReportPassword"
          >
            {{ $i18n.t('$l.app.editNow') }}
          </v-btn>
        </template>
      </basic-service-required>

      <BasicUserData
        v-if="editPdfReportPassword"
      />
    </basic-dialog>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

import BasicUserData from '@/components/profile/BasicUserData'

export default {
  name: 'PdfView',
  components: {
    pdf,
    BasicUserData
  },
  data () {
    return {
      callResult2: { finished: false, authorized: false, error: null, info: null },
      pdfDocumentLoadingTask: null,
      numPages: undefined,
      showNoo: false,
      pdfEncryptionHintDialog: false,
      editPdfReportPassword: false
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  mounted () {
    this.loadPdfReport()
  },
  methods: {
    loadPdfReport () {
      this.callResult2.finished = false
      this.callResult2.error = null
      this.pdfDocumentLoadingTask = null
      this.$xapi.post('archive/report', this.currentCase.caseId, { responseType: 'arraybuffer' })
        .then(r => {
          if (r.status === 204) {
            return
          }
          // this.pdfBase64data = btoa(String.fromCharCode(...new Uint8Array(r.data)))
          var byteArray = new Uint8Array(r.data)
          var file = new Blob([byteArray], { type: 'application/pdf;base64' })
          var fileURL = URL.createObjectURL(file)
          this.pdfDocumentLoadingTask = pdf.createLoadingTask(fileURL)
          this.pdfDocumentLoadingTask.promise
            .then(pdf => {
              this.numPages = pdf.numPages
            })
            .catch(() => {
              this.callResult2.error = this.$i18n.t('$l.errorcode.default')
            })
            .finally(() => {
              this.callResult2.finished = true
            })
        })
        .catch(e => {
          this.callResult2.error = e.response.localizedMessage
          this.callResult2.finished = true
        })
    },
    downloadPdf () {
      this.pdfEncryptionHintDialog = true
    },
    doDownloadPdf () {
      this.pdfEncryptionHintDialog = false
      if (this.currentCase.status === 'CLOSED') {
        this.callResult2.error = null
        this.$xapi.post('archive/report/enc', this.currentCase.caseId, { responseType: 'arraybuffer' })
          .then(r => {
            if (r.status === 204) {
              return
            }
            // var byteArray = new Uint8Array(r.data)
            // var file = new Blob([byteArray], { type: 'application/pdf;base64' })
            // var fileURL = URL.createObjectURL(file)
            let pdfBase64data = btoa(String.fromCharCode(...new Uint8Array(r.data)))
            const linkSource = `data:application/pdf;base64,${pdfBase64data}`
            const downloadLink = document.createElement('a')
            document.body.appendChild(downloadLink)
            downloadLink.href = linkSource
            downloadLink.target = '_self'
            downloadLink.download = 'report.pdf'
            downloadLink.click()
            this.$emit('downloadPdfProgressFinished')
          }).catch(e => {
            this.callResult2.error = e.response.localizedMessage
          }).finally(() => {
            this.$emit('downloadPdfProgressFinished')
          })
      }
    },
    pdfEncryptionHintDialogClose () {
      this.pdfEncryptionHintDialog = false
      this.$emit('downloadPdfProgressFinished')
    },
    checkWarnings () {
      let sanctionHitConfirmed = false
      this.currentCase.participants.forEach(p => {
        p.investigations.forEach(i => {
          if (i.clientApi === 'EUSL' || i.clientApi === 'CSSDN' || i.clientApi === 'USDN') {
            if (i.result.userDefinedRiskLevel > 0) {
              sanctionHitConfirmed |= true
            }
          }
        })
      })
      if (sanctionHitConfirmed) {
        return this.$i18n.t('$l.investigation.sanctionListHitConfirmed')
      } else {
        return null
      }
    }
  }
}
</script>
